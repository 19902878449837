import React from 'react';

import HomeHeader from "./mods/headers"
import App from './mods/tmp';

/******* FUCKING APP LIKE */
function getScreenSizeForTextSize() {
  if(window.innerWidth < 768) {
      return true
  } else {
      return false
  }
}

const HomePage = () => {

  // check if rul containt: ?mobile=true
  // if true => render mobile version

  if(window.location.search.includes("mobile=true")){
    return (
      document.location.href = "/login"
    );
  }

  return (
    <>
        <App />
    </>
  );

  return (
    <>
        <HomeHeader />
    </>
  );
}

export default HomePage;
