import React from 'react';
import { Flex, Button, Box, Dialog } from '@radix-ui/themes';
//import { } from '@radix-ui/react-icons';


/**
 * This is the header of the logged user
 * This is used to diplay the main informations
 */
const Pay = (content) => {

    return  <Dialog.Root>
            <Dialog.Trigger>

                <Box>

                {
                    content.content === "classik" ? <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                    }}>
                        <Button size="3" variant="surface" color='brown'>
                            💶 Passer au paiement
                        </Button>
    
                    </Box> : <></>
                }

                {
                    content.content === "annuel" ? <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                    }}>
                        <Button size="3" color="bronze">
                            💶 Passer au paiement
                        </Button>
                    </Box> : <></>
                }

                {
                    content.content === "life" ? <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                    }}>
                        <Button size="3" variant="surface" color='brown'>
                            💶 Passer au paiement
                        </Button>
                    </Box> : <></>
                }

                {
                    content.content === "telegram" ? <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                    }}>
                        <Button size="3" variant="surface"  color='amber'>
                            💶 Passer au paiement
                        </Button>
                    </Box> : <></>
                }
        

                </Box>
                
                
            </Dialog.Trigger>

            <Dialog.Content style={{ maxWidth: 550 }}>
                <form
                onSubmit={async (event) => {
                    event.preventDefault();
                    const promocode = document.getElementById('promo').value;
                    // get witch button is clicked
                    const clicked = event.nativeEvent.submitter.id;
                    
                    const cookie = document.cookie.split(';').find(cookie => cookie.startsWith('token') || cookie.startsWith(' token'));
                    const token = cookie ? cookie.split('token=')[1] : null;

                    try {
                        // contact the api
                        const req = await fetch('https://kissaten.koffy.finance/subscribe/new', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${token}`
                            },
                            body: JSON.stringify({
                                type: content.content,
                                promocode: promocode ? promocode : "none",
                                provider: clicked,
                            }),
                        })
                        const res = await req.json();
                        if(res.url) {
                            window.location.href = res.url;
                        }
                    } catch(err) {}
                }}>
                    <Dialog.Title>
                        {
                            content.content === "classik" ?
                            "Koffy Classik ☕" :
                            ""
                        }
                        {
                            content.content === "annuel" ?
                            "Koffy Annuel ☕" :
                            ""
                        }
                        {
                            content.content === "life" ?
                            "Koffy Life ☕" :
                            ""
                        }
                        {
                            content.content === "telegram" ?
                            "Koffy Telegram ☕" :
                            ""
                        }
                    </Dialog.Title>
                    <Dialog.Description size="2" mb="4">
                        Dans le cadre de la prise d'abonnement, vous avez le choix entre régler votre abonnement par carte bancaire ou par cryptomonaie.<br/><br/>

                        Mais avant, si vous avez un code promo (uniquement pour les paiements par carte), vous pouvez l'entrer ici :
                    </Dialog.Description>

                    <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                    }}>
                        <Flex direction="column" gap="3">
                            <label>
                                <input type='text' id='promo' placeholder='Card promo code' style={{
                                    border: "1px solid #eaeaea",
                                    borderRadius: "4px",
                                }}/>
                            </label>
                        </Flex>
                    </Box>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                                Cancel
                            </Button>
                        </Dialog.Close>
                        {
                            content.content !== "life" ? <Dialog.Close>
                                <Button type='submit' id="stripe">Par carte 💳</Button>
                            </Dialog.Close> : <></>
                        }
                        <Dialog.Close>
                        {
                            content.content !== "telegram" ? <Dialog.Close>
                                <Button type='submit' id="crypto" variant="surface">Par cryptomonaie Ξ</Button>
                            </Dialog.Close> : <></>
                        }
                            
                        </Dialog.Close>
                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>


}

export default Pay;