import React from 'react';
import { Box, Text, Flex } from '@radix-ui/themes';


import Top from "./top"
import Banner1 from "./banner1"
import Inside from './inside';
import Tarrif from "./tarrif"
import Business from "./business"
import Footer from '../../utils/footer'


function getScreenSizeForMargin() {
    if(window.innerWidth < 768) {
        return "5px"
    } else {
        return "13vh"
    }
}

function getScreenSizeForTitleMargin() {
    if(window.innerWidth < 768) {
        return "1vh"
    } else {
        return "8vh"
    }
}

function getScreenSizeForTextSize() {
    if(window.innerWidth < 768) {
        return "2"
    } else {
        return "3"
    }
}

const HomeHeader = () => {

  return (
    <div>

        <div style={{
            backgroundImage: `url('/imgs/background.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
        }}>
                <Top/>

                <Box style={{
                    width: '100%',
                    minHeight: '92vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                    
                    <Box
                        style={{
                            marginTop: getScreenSizeForTitleMargin()
                        }}
                        p="7">
                        <Flex direction="column" gap="3" style={{ textAlign: 'center', maxWidth: "80vh"}}>
                            <Text size="9" weight="bold" style={{
                                color: '#ffffff',
                                fontWeight: 'bold'
                            }}>Ready to be read while</Text>
                            <Text size="9" style={{
                                color: '#ffffff',
                                fontWeight: 'bold'
                            }} weight="bold">Drinking your Koffy</Text>
                            <Text size={getScreenSizeForTextSize()} style={{
                                color: '#FFFFFF',
                            }}>
                                Une web-application semblable à un <i>quotidien</i> qui vous permet d’être au courant de <i>l’ensemble</i> de l’écosystème des cryptomonnaies.
                            </Text>
                        </Flex>
                    </Box>



                </Box>

        </div>


        <Banner1 />

        <Box style={{
            marginRight: getScreenSizeForMargin(),
            marginLeft: getScreenSizeForMargin(),
            justifyContent: 'center',
        }}>
            <Inside />

            <Tarrif />

            <Business />


        </Box>


        <Footer />
        
    </div>
  );
}

export default HomeHeader;
