import React from 'react';
import { Text, Box, Card, Strong, Inset, Button } from '@radix-ui/themes';
import { ArrowRightIcon } from '@radix-ui/react-icons';

import '@radix-ui/colors/gray.css';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Pay from './popup/pay.jsx';
import Pay2 from './popup/pay2.jsx';


const SubscribeBox2 = (userData) => {

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return <>
        <Box style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "45px",
            display: "flex",
            alignContent: "center",
        }}>
            <Box style={{
                height: "100%",
                width: "90%",
                alignItems: "center",
                justifyContent: "center",
            }}>


                <Slider {...sliderSettings} style={{marginTop: '30px'}}>
                
                    {/** A koffy price */}
                    <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                        alignContent: "center",
                    }}>
                        <Card style={{
                            height: '100%',
                            width: '90%',
                            marginLeft: '5%',
                        }}>
                            <Inset clip="padding-box" side="top" pb="current">
                                <img
                                src="/imgs/register/simple.png"
                                alt="Bold typography"
                                style={{
                                    display: 'block',
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: 160,
                                    backgroundColor: 'var(--gray-5)',
                                }}
                                />
                            </Inset>
                            

                            <Box style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Text size={"5"}><Strong>Koffy Classik</Strong></Text>
                                <Box style={{ textAlign: "right", marginRight: "1px" }}>
                                    <Text size={"5"}>
                                        <strong>2€</strong>
                                    </Text>
                                    <Text size={"3"}>
                                        /mois
                                    </Text>
                                </Box>
                            </Box>


                            <Text size={"3"}>

                                {
                                    userData.userData.usrsett_lang === "FR" ?
                                    "L'abonnement vous donne accès à:" :
                                    "The subscription gives you access to:"
                                }
                                <br /><br />

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "auto", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Accès à l’édition complète" :
                                        "Access to the full edition"
                                    }
                                    <br /></Text>
                                </Box>

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "auto", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Une librairie d'articles" :
                                        "An article library"
                                    }
                                    <br /></Text>
                                </Box>

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "auto", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Paramétrage des devises" :
                                        "Currency settings"
                                    }
                                    <br /></Text>
                                </Box>

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "4px", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Accès à la machine à remonter le temps" :
                                        "Access to the time machine"
                                    }
                                    <br /></Text>
                                </Box>

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "4px", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    Podcast 
                                    <br /></Text>
                                </Box>

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "4px", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Mykofeed - flux d’actu personnalisé" :
                                        "Mykofeed - personalized news feed"
                                    }
                                    <br /></Text>
                                </Box>
                                

                                <br />

                                <i>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "* facturation mensuelle" :
                                        "* monthly billing"
                                    }
                                </i>
                                <br />
                                <br />

                            </Text>

                            <Pay content={"classik"}/>

                        </Card>
                    </Box>

                    {/** A koffy price */}
                    <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                        alignContent: "center",
                        border: "1px solid #000000",
                    }}>
                        <Card style={{
                            height: '100%',
                            width: '90%',
                            marginLeft: '5%',
                            border: "2px solid #a07553",
                            borderRadius: "10px",
                        }}>
                            <Inset clip="padding-box" side="top" pb="current">
                                <img
                                src="/imgs/register/year.png"
                                alt="Bold typography"
                                style={{
                                    display: 'block',
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: 160,
                                    backgroundColor: 'var(--gray-5)',
                                }}
                                />
                            </Inset>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Text size={"5"}><Strong>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Koffy Annuel" :
                                        "Koffy Annual"
                                    }    
                                </Strong></Text>
                                <Box style={{ textAlign: "right", marginRight: "1px" }}>
                                    <Text size={"5"}>
                                        <strong>20€</strong>
                                    </Text>
                                    <Text size={"3"}>
                                        /an
                                    </Text>
                                </Box>
                            </Box>
                            <Text size={"3"}>

                                {
                                    userData.userData.usrsett_lang === "FR" ?
                                    "L'abonnement vous donne accès à:" :
                                    "The subscription gives you access to:"
                                }
                                <br /><br />

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Toutes les fonctionnalités de Koffy classik, mais encore moins cher" :
                                        "All the features of Koffy classik, but even cheaper"
                                    }
                                    <br /></Text>
                                </Box>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <i>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "* facturation annuelle" :
                                        "* annual billing"
                                    }
                                </i>
                                <br />
                                <br />

                            </Text>
                            

                            <Pay content={"annuel"}/>
                        </Card>
                    </Box>


                    {/** A koffy price */}
                    <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                        alignContent: "center",
                    }}>
                        <Card style={{
                            height: '100%',
                            width: '90%',
                            marginLeft: '5%',
                        }}>
                            <Inset clip="padding-box" side="top" pb="current">
                                <img
                                src="/imgs/register/life.jpg"
                                alt="Bold typography"
                                style={{
                                    display: 'block',
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: 160,
                                    backgroundColor: 'var(--gray-5)',
                                }}
                                />
                            </Inset>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Text size={"5"}><Strong>Koffy Lifetime</Strong></Text>
                                <Box style={{ textAlign: "right", marginRight: "1px" }}>
                                    <Text size={"5"}>
                                        <strong>200€</strong>
                                    </Text>
                                    <Text size={"3"}>
                                        / à vie
                                    </Text>
                                </Box>
                            </Box>
                            <Text size={"3"}>

                                {
                                    userData.userData.usrsett_lang === "FR" ?
                                    "L'abonnement vous donne accès à:" :
                                    "The subscription gives you access to:"
                                }
                                <br /><br />

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Toutes les fonctionnalités de Koffy classik, avec un abonnement destiné au pure player de la crypto" :
                                        "All the features of Koffy classik, but even cheaper for the pure player of the crypto world"
                                    }
                                    <br /></Text>
                                </Box>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <i>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "* un paiement pour la vie, uniquement disponible en cryptomonnaie" :
                                        "* a payment for life, only available in cryptocurrency"
                                    }
                                </i>
                                <br />

                            </Text>
                            

                            <Pay content={"life"}/>
                        </Card>
                    </Box>


                    {/** A koffy price */}
                    <Box style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "25px",
                        display: "flex",
                        alignContent: "center",
                    }}>
                        <Card style={{
                            height: '100%',
                            width: '90%',
                            marginLeft: '5%',
                        }}>
                            <Inset clip="padding-box" side="top" pb="current">
                                <img
                                src="/imgs/register/telegram.png"
                                alt="Bold typography"
                                style={{
                                    display: 'block',
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: 160,
                                    backgroundColor: 'var(--gray-5)',
                                }}
                                />
                            </Inset>
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <Text size={"5"}><Strong>Koffy Telegram +</Strong></Text>
                                <Box style={{ textAlign: "right", marginRight: "1px" }}>
                                    <Text size={"5"}>
                                        <strong>49€</strong>
                                    </Text>
                                    <Text size={"3"}>
                                        /mois
                                    </Text>
                                </Box>
                            </Box>
                            <Text size={"3"}>

                                {
                                    userData.userData.usrsett_lang === "FR" ?
                                    "L'abonnement vous donne accès à:" :
                                    "The subscription gives you access to:"
                                }
                                <br /><br />


                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <ArrowRightIcon style={{color: "#000000", marginRight: "5px", height: "20px", marginTop: "4px", width: "10%"}}/>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Les news de l'édition, tous les jours dans votre canal telegram" :
                                        "The news of the edition, every day in your telegram channel"
                                    }
                                    <br /></Text>
                                </Box>
                                <br />

                                <Box style={{
                                    width: "100%",
                                    display: "flex",
                                }}>
                                    <Text size={"3"} style={{width: "90%"}}>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "Plus toutes les fonctionnalités de Koffy classik" :
                                        "All the features of Koffy classik"
                                    }
                                    <br /></Text>
                                </Box>
                                
                                <br />
                                <br />
                                <br />
                                <br />

                                <i>
                                    {
                                        userData.userData.usrsett_lang === "FR" ?
                                        "* facturation mensuelle" :
                                        "* monthly billing"
                                    }
                                </i>


                            </Text>
                            

                            <Pay content={"telegram"}/>
                        </Card>
                    </Box>



                </Slider>

            </Box>
        </Box>
    
    </>
}

export default SubscribeBox2;